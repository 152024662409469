<script>
export default {};
</script>
<template>
  <main id="main" class="pb-0">
    <div class="jumbotron d-flex align-items-center">
      <div class="container py-4">   
        <h1 class="text-uppercase text-white" style="font-weight: 700">
          Digitalizzazione del Giornale dello Spettacolo <br />e Borsa Film
        </h1>
        <h2
          style="
            color: var(--secondary);
            font-family: 'Barlow Condensed', sans-serif;
            text-transform: uppercase;
            font-weight: 600;
          "
        >
          Qui potrai trovare oltre cinquant'anni di cinema italiano e non
          <br />per la prima volta in formato digitale
        </h2>
        <router-link
          :to="{ name: 'progetto-digitalizzazione' }"
          class="link_light me-1"
        >
          Il Progetto
        </router-link>
        <router-link
          :to="{ name: 'guida-portale' }" class="link_secondary"
          >Guida al portale</router-link>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped></style>
